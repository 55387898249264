<template>
    <div>
        <!-- <h1>Fee Schedule for {{ fee_set }}</h1> -->
        <h1>{{ header }}</h1>
        <div class="pad-20">
            <div class="bottom-15 max500 pad-x-10">
                <label class="dark-text weight-600 bottom-10 block" for="">Financial Class</label>
                <Dropdown
                    :disableEmpty="true"
                    :id="'financial_class'"
                    :name="'financial_class'"
                    :label="'Choose a Financial Class to View and Edit Associated Fees'"
                    :options="financial_classes"
                    @input="switch_financial_class_set($event)"
                    v-model="financial_class"
                    class="font-12"
                />
            </div>
            <template v-if="financial_class == 'commercial_insurance'">
                <div class="bottom-30 max500 pad-x-10">
                    <label class="font-12">Choose Payer</label>
                    <Multiselect
                        v-model="insurance_payer"
                        :options="insurance_payers_list"
                        track-by="id"
                        :options-limit="1000"
                        label="payer_name"
                        @select="select_insurance_payer"
                    />
                </div>
            </template>
            <hr class="purple wide-40" />
            <div class="dark-text weight-600 margin-0 font-14">Procedure Code (CPT)</div>
            <div
                v-for="(service_fee, key) in service_fees"
                :key="service_fee.data_id"
                class="flex bottom single-service"
            >
                <div class="multiselect disabled">
                    <!-- <label class="block" for="">Procedure Code (CPT)</label> -->
                    <input type="hidden" :value="key" />
                    {{ service_fee.service_code }} : {{ service_fee.abbrev }}
                </div>
                <div class="fee">
                    <label class="font-12">Enter Fee</label>
                    <CurrencyInput
                        @currency="debounceHandleCurrencyChange($event, service_fee, 'cost')"
                        :key="'input_cost' + service_fee.data_id"
                        :initialValue="service_fee.cost"
                    />
                </div>
                <div v-if="financial_class != 'self_pay' && financial_class != 'self_pay_custom'" class="fee">
                    <label class="font-12">Reimbursement</label>
                    <CurrencyInput
                        @currency="debounceHandleCurrencyChange($event, service_fee, 'expected_reimbursement')"
                        :key="'input_expected_reimbursement' + service_fee.data_id"
                        :initialValue="service_fee.expected_reimbursement"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /**
     * We want the option to be disabled from being selected if it's already selected,
     *
     */
    import { debounce } from 'lodash';

    const api_root = 'service_fees';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import { generateId, calibrateMenu } from '@/util/genericUtilityFunctions';
    import { serviceCodes } from '@/util/apiRequests';

    export default {
        components: { CurrencyInput },
        name: 'FeeSchedule',
        props: {
            fee_set: [String, Number]
        },
        data() {
            return {
                loading: 1,
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                service_fees: [],
                justModified: null,
                originalOptions: [],
                financial_class: 'self_pay',
                insurance_payer_id: 0,
                financial_classes: [
                    'self_pay',
                    'self_pay_custom',
                    'commercial_insurance',
                    'medicaid',
                    'medicare',
                    'workers_comp'
                ],
                insurance_payer: {},
                insurance_payers_list: [],
                calibrateMenu
            };
        },
        computed: {
            header() {
                return this.headerText || this.pageMeta.title;
            }
        },
        methods: {
            async switch_financial_class_set(e) {
                if (this.financial_class != 'commercial_insurance') {
                    this.insurance_payer_id = 0;
                    await this.get_fee_schedule();
                } else {
                    if (this.insurance_payers_list[0]) {
                        this.insurance_payer = this.insurance_payers_list[0];
                        await this.select_insurance_payer(this.insurance_payer);
                    }
                }
            },
            async select_insurance_payer(e) {
                this.financial_class = 'commercial_insurance';
                this.insurance_payer_id = e.id;
                await this.get_fee_schedule();
            },
            async get_insurance_payers() {
                const res = await this.$api.get(`/settings/setting/insurance_payers/company/0`);
                if (res.data.value) {
                    this.insurance_payers_list = JSON.parse(res.data.value);
                }
            },
            async get_fee_schedule() {
                this.loading = 1;
                let body = {
                    financial_class: this.financial_class,
                    insurance_payer_id: this.insurance_payer_id
                };
                const res = await this.$api.post(`/${api_root}/fee_schedule/company/${this.level_id}`, body);

                this.rows = res.data;

                for (let k in this.rows) {
                    if (this.rows[k].data_id == 0) {
                        this.rows[k].generated = true;
                        this.rows[k].data_id = generateId();
                        this.rows[k].level = 'company';
                        this.rows[k].financial_class = this.financial_class;
                        this.rows[k].insurance_payer_id = this.insurance_payer_id;
                    }
                }

                this.service_fees = this.rows
                    .filter((fee) => fee.level === 'company')
                    .filter((fee) => fee.code_type === 'service')
                    .map((element) => {
                        element.selected = {
                            service_code: element.service_code,
                            long_descrip: element.long_descrip,
                            id: element.service_code_id
                        };
                        element.disabled = true;
                        return element;
                    });
                this.options = this.options.filter((element) => {
                    if (this.service_fees.some((fee) => element.id == fee.selected?.id)) {
                        return false;
                    }
                    return true;
                });
                this.loading = 0;
            },
            async init() {
                this.level_id = this.$store.state.user.company_id;
                await this.getServiceCodeOptions();
                await this.get_fee_schedule();
                this.loading = 0;
            },
            async validate() {
                return 1;
            },
            serviceCodeDesc({ service_code, long_descrip }) {
                if (service_code && long_descrip) {
                    return `${service_code} ${long_descrip}`;
                }
                return 'Select an option';
            },
            handleCurrencyChange(newCurrencyTotal, item, field) {
                this.$set(item, field, this.$getNumFromCurrency(newCurrencyTotal));
                this.update_setting(item);
            },
            debounceHandleCurrencyChange: debounce(function(newCurrencyTotal, item, field) {
                this.handleCurrencyChange(newCurrencyTotal, item, field);
            }, 400),
            async getServiceCodeOptions() {
                let result = await this.$api.get(serviceCodes.getServiceCodes());
                let options = result.data?.map((element) => {
                    return { service_code: element.service_code, long_descrip: element.long_descrip, id: element.id };
                });
                this.options = options;
                this.originalOptions = this.options;
            },
            async update_setting(item) {
                try {
                    if (Object.keys(item.selected).length <= 0) {
                        return;
                    }
                    //new
                    if (item.generated == true) {
                        let result = await this.$api.post(
                            `/${api_root}/${this.level}/${this.level_id}/${item.selected.id}`,
                            { item }
                        );
                        this.$toasted.success('Record successfully created');

                        this.$set(item, 'generated', false);
                        //Apparently you can't do the below code commented out, because it will mess with the CurrencyInput by preventing a user from typing.
                        // this.$set(item, 'data_id',  result.data.recId[0]);

                        this.$set(item, 'id_after_generated_is_false', result.data.recId[0]);
                        this.options = this.calibrateMenu(item, null, 'id', this.options);
                        this.$set(item, 'disabled', true);
                    }

                    //update
                    else {
                        let adata_id = 0;
                        if (item.id_after_generated_is_false) {
                            adata_id = item.id_after_generated_is_false;
                        } else {
                            adata_id = item.data_id;
                        }
                        await this.$api.put(`/${api_root}/${adata_id}`, { item });

                        this.$toasted.success('Successfully updated Record.');
                    }
                } catch (err) {
                    this.$toasted.error('Failed to update Record. Please try again later.');
                }
            }
        },
        async created() {
            await this.get_insurance_payers();
            await this.init();
        }
    };
</script>
